@import url(https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.navbar{
  z-index: 4;
}

.container_c {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sivu-bg1 {
  background-color: #eb6fca;
}
.sivu-bg2 {
  background-color: white;
}
.nav-link {
  font-family: "Sansita Swashed", cursive;
  font-size: 1.4em;
}

.fontti {
  font-family: "Sansita Swashed", cursive;
}
.logo {
  height: 105px;
  width: 200px;
}

/*Ajankohtaista laatikon tyylimäärittelyt  */

.loota1 {
  background-color: rgb(217, 170, 255);
  border-radius: 9px;
}
.loota2 {
  background-color: white;

  border-radius: 9px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

/*  */

.bannerOverlay {
  background: rgb(0, 0, 0, 0.7);
  overflow: hidden;
  width: inherit;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  min-height: 40rem;
}

/* .bannerKuva {
  background: url('./img/kirppis.jpg') no-repeat;
  background-size: 100% 100%;
  height: auto;
  overflow: hidden;
} */

img {
  height: 100%;
  width: 100%;
}


.bannerKuva {
  background-image: url(/static/media/kirppis.110bc996.webp);
  width: 100%;
  background-size: contain;
}

.esittelyteksti {
  width: 100%;
  color: white;
  font-size: 1.2em;
  padding-top: 5%;
  padding-left: 5%;
  padding-bottom: 5%;
}
@media only screen and (max-width: 1400px) {
  .esittelyteksti {
    text-align: center;
  }
}

.poytavaraus {
  width: 100%;
  margin: 5% 2% 5% 2%;
  border: 6px outset #f200b1;
  border-radius: 15px;
  background-color: white;
  display: flex;
  padding-left: 1rem;
}

.poytavaraus_1 {
  width: 40%;
  margin-top: 10%;
  text-align: center;
}

.poytavaraus_2 {
  align-self: center;
  text-align: center;
  font-weight: bold;
  width: 60%;
}

.varaus_painike1 {
  background-color: #f200b1 !important;
  border: none;
  padding: 10px;
  padding-left: 4px;
  padding-right: 4px;
  width: 70%;
  font-size: large;
}

.varaus_painike {
  background-color: #f200b1 !important;
  border: none;
  padding: 10px;
  width: 60%;
  font-size: large;
}

.varaus_painike:hover {
  background-color: #860564 !important;
}

.varaus_painike1:hover {
  background-color: #860564 !important;
}

.youtube {
  display: flex;
  justify-content: flex-start;
}
@media only screen and (max-width: 1400px) {
  .youtube {
    display: none !important;
  }
  .videoLinkki {
    display: flex !important;
    justify-content: center;
  }
}
@media only screen and (max-width: 993px) {
  .youtube {
    display: flex !important;
    justify-content: center;
  }
  .videoLinkki {
    display: none !important;
  }
}
@media only screen and (max-width: 576px) {
  .youtube {
    display: none !important;
  }
  .videoLinkki {
    display: flex !important;
    justify-content: center;
  }
}

.ajankohtaista_FB {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}

.ajankohtaista {
  padding: 10px;
  background-color: #c5f077;
  border: 4px solid white;
}

.ajankohtaista ul li {
  list-style-type: circle;
  font-size: 1.15em;
  padding-bottom: 8px;
  padding-left: 15px;
  position: relative;
}

.FacebookBox {
  background-color: white;
  height: auto;
  padding: 0 !important;
  overflow: scroll;
}

.fb-backround {
  margin-right: 4px;
  margin-left: 4px;

  background-image: url(/static/media/fbbg.c0be08fd.jpg);
  width: 100%;
  background-size: cover;
}

.fb-page {
  width: auto;
  text-align: center;
}

.fb-page,
.fb-page span,
.fb-page span iframe[style] {
  height: 100% !important;
}

/*Infokarusellin css määritykset*/

.karuselli {
  background-color: #eb6fca;
  border-radius: 15px;
  border: 4px solid white;
  padding: 5px;
}

/* matka-auto */
.autokuva {
  width: auto;
}

.matkailuauto_laatikko {
  position: relative;
}

.lisatiedotNappi {
  font-size: 1.2em;
  padding-left: 4px;
  padding-right: 6px;
  padding-bottom: 2px;
  border: white solid 2px;
  border-radius: 15px;
}

.videoNappi {
  font-size: 1.08em;
  padding-left: 4px;
  padding-right: 6px;
  padding-bottom: 2px;
  color: white;
  text-decoration: none;
  border: white solid 2px;
  border-radius: 15px;
}

.esittelyteksti a:hover {
  background-color: white;
  color: black;
}

.naytalisaa:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

@media only screen and (min-width: 991px) {
  .naytalisaa {
    display: none;
  }
}

.matkailuauto_laatikko a {
  text-decoration: none;
  color: white;
}

.matkailuauto_laatikko a:hover {
  background-color: white;
  color: #eb6fca !important;
}

.matkailuauto_laatikko a:hover > i {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.matkailuauto_laatikko i {
  transition: -webkit-transform 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out, -webkit-transform 0.7s ease-in-out;
}

.laatikon_otsikko {
  width: 95%;
  position: absolute;
  top: 6%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.616);
  color: black;
  padding: 5px;
}
/*  */

.karuselli_laatikko {
 height: 100% !important;
}

.hinnasto2 {
  display: flex;
  height: inherit;
  justify-content: 'space-evenly';
  align-items: 'stretch';
  padding-bottom:10%;
}

.laatikon2_otsikko {
  color: white;
  padding: 5px;
  text-align: center;
}

.hinnasto_laatikko {
  border: 4px outset #f200b1;
  border-radius: 15px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 320px
}

.hinnasto_laatikko_iso {
  height: 50%;
  border: 4px outset #f200b1;
  border-radius: 15px;
  background-color: white;
  padding: 0px !important;
}

.hinnasto_laatikko_iso h4 {
  padding-bottom: 4%;
}

.hinnasto_laatikko p {
  font-size: 1.05em;
}
.hinnasto_laatikko_iso p {
  font-size: 1.1em;
}

@media only screen and (max-width: 1350px) {
  .hinnasto_laatikko_iso {
    height: auto;
  }
}

.myynti_varaaminen {
  background-color: #7e1e65ca;
  border-radius: 10px;
  padding: 15px;
  color: white;
  font-size: 1.4em;
  height: 100%;
}

.myynti_varaaminen .varaus_painike {
  border-radius: 10px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 50%;
}

.puh_nro {
  border: white solid 2px;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.puh_nro2 {
  border: white solid 2px;
  border-radius: 10px;
}

@media only screen and (max-width: 991px) {
  .puh_nro2 {
    border: none;
  }
}

.provisiomyynti {
  font-size: 1.4em;
  color: white;
  border: white 2px solid;
  border-radius: 10px;
  padding: 10px;
  position: relative;
 
}

.provisiomyynti2 {
  text-align: left !important;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 2%;
  padding-right: 2%;
  margin: 10px;
  border: white 2px solid;
  border-radius: 10px;
  margin-bottom: 15%;
  background-color: #7e1e65ca;
}

.iso_puh_nro {
  font-size: 1.4em;
}

.provisiomyynti .iso_puh_nro {
  margin-bottom: 10%;
}

/* TÄSSÄ ON KAIKKI CONTAINERIN ERI MUUNNOSVAIHEET, TÄNNE RESPONSIIVISUUS */
@media only screen and (max-width: 1199px) {
  .fb-page {
    width: auto !important;
  }

  .poytavaraus_1 {
    width: 50%;
    margin-top: 20%;
  }

  .poytavaraus_2 {
    width: 50%;
  }

  .poytavaraus_2 h3 {
    font-size: 1.4em !important;
  }

  .hinnasto_laatikko h2 {
    font-size: 1.5em !important;
  }

  .hinnasto_laatikko h4 {
    font-size: 1em !important;
  }

  .hinnasto_laatikko_iso {
    height: 40%;
  }

  .myynti_varaaminen {
    font-size: 1em;
  }
}

@media only screen and (max-width: 993px) {
  .bannerOverlay {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .bannerKuva {
    background-size: cover;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .fb-page {
    width: 100% !important;
    align-self: center;
  }

  .fb-page,
  .fb-page span,
  .fb-page span iframe[style] {
    height: 700px !important;
  }

  .FacebookBox {
    display: none;
    border: none;
  }

  .bannerOverlay {
    text-align: center;
  }

  .esittelyteksti,
  .poytavaraus {
    width: 100% !important;
  }

  .bannerOverlay {
    flex-direction: column;
    padding-bottom: 0;
  }

  .poytavaraus {
    overflow: visible;
    margin: 0;
    width: 90%;
  }

  .poytavaraus_1 {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .poytavaraus_2 h3 {
    font-size: 1.5em !important;
  }

  .autokuva {
    height: 70%;
    width: 80%;
  }

  .hinnasto_laatikko h2 {
    font-size: 2em !important;
  }

  .hinnasto_laatikko h4 {
    font-size: 1.5em !important;
  }

  .hinnasto_laatikko_iso {
    height: 50%;
  }

  .myynti_varaaminen {
    font-size: 1.2em;
  }

  .iso_puh_nro {
    font-size: 1em;
  }
}

@media only screen and (max-width: 764px) {
  .poytavaraus {
    flex-direction: column;
    margin-top: 10px;
  }

  .poytavaraus_1,
  .poytavaraus_2 {
    width: 100% !important;
    padding-bottom: 10px;
  }

  .hinnasto_laatikko_iso {
    height: auto;
  }
}

.footer {
  position: relative;
  z-index: 4;
  font-size: 1.2em;
  background-color: #eb6fca;
}
.fa-2x {
  font-size: 2.18em !important;
}
@media only screen and (max-width: 1400px) {
  .footer {
    font-size: 1.15em !important;
  }
  .footer h3 {
    padding-bottom: 0px !important;
  }
  .fa-2x {
    font-size: 2.4em !important;
  }
}

.footer h3 {
  font-family: "Sansita Swashed", cursive;
  font-size: 1.5em;
  color: white;
  margin-bottom: 15px;
}

.footer p {
  color: white;
}

.footer .text-muted {
  font-size: 0.9em;
}

p .fa-facebook:hover,
p .fa-instagram:hover {
  transition: 0.5s;
  color: #691553 !important;
}

/* myyntivinkit */
.myyntivinkit div {
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}
.myyntivinkit p {
  font-size: 1.08em;
  color: white;
}
.myyntivinkit h1 {
  color: white;
}
.vinkitkuva {
  background-image: url(/static/media/mvbg.afee79c3.webp);
  width: 100%;
  background-size: cover;
  border-radius: 12px;
}
.tummennus {
  background-color: rgba(0, 0, 0, 0.61);
  border-radius: 12px;
  margin: auto;
}

@media only screen and (max-width: 576px) {
  .palkit {
    border-left: 0px solid white !important;
  }
  .vinkitkuva,
  .tummennus {
    border-radius: 0px;
  }
}
.palkit {
  border-left: 1px solid white;
}


.background_hinnasto{
  margin: auto;
  border-radius: 12px;
  background-image: url(/static/media/mvbg.afee79c3.webp);
  background-size: cover;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.parallax2 {
  /* The image used */
  background-image: url(/static/media/ruusunenkartta1.c014f746.jpg);
  border-radius: 12px;
  margin: auto;

  /* Set a specific height */
  min-height: 600px;
  background-position: center;

  background-size: cover;
}

.hinnoittelu_container {
  z-index: 2;
  position: relative;
  margin-left: 20%;
  margin-right: 20%;
}

.myyntipaikat_hinnasto {
  font-size: larger;
  padding: 55px;
  border-right: rgba(255, 255, 255, 0.671) solid 1px;
  border-left: rgba(255, 255, 255, 0.671) solid 1px;
  background-color: rgba(0, 0, 0, 0.473);
}

@media only screen and (max-width: 768px) {
  .myyntipaikat_hinnasto {
    border: none;
  }
}

.hinnasto_otsikko {
  background-color: rgba(0, 0, 0, 0.473);
  margin-bottom: 0;
}

.vasen_tasaus .varaus_painike {
  width: 30%;
}

.hinnasto_laatiko .varaus_painike {
  width: 60% !important;
}

.vasen_tasaus {
  margin-left: 5%;
  text-align: left;
}

.hinnoittelu_teksti {
  padding-right: 30%;
}

.otsikko_tasaus {
  padding-left: 5%;
}

.myyntipaikat_hinnasto .hinnasto_laatikko {
  height: 100% !important;
}

.hinnasto_laatikko img {
  padding: 20px !important;
}

.myyntipaikat_hinnasto .provisiomyynti2 {
  background-color: rgba(0, 0, 0, 0.233) !important;
  margin-left: 0;
  margin-bottom: 20px;
}


@media only screen and (max-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
  .hinnoittelu_container {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

}

@media only screen and (max-width: 700px) {
  .myyntipaikat_hinnasto {
    padding: 2px;
  }
  .vasen_tasaus .varaus_painike {
    width: 90%;
  }

  
  .vasen_tasaus {
    margin-left: 0%;
    text-align: left;
    padding: auto;
  }
  .hinnoittelu_teksti {
    padding-right: 0%;
    padding-left: 5px;
  }
}

.ohjeet_ajankohtaista .btn-outline-primary {
  font-size: smaller;
}

.ohjeet_ajankohtaista .btn-outline-primary:hover {
  background-color: rgba(255, 255, 255, 0);
  color: blue;
  cursor: auto;
}

.taulu table,
.taulu th,
.taulu td {
  border: 1px solid white;
  color: white;
  padding: 30px;
}

.taulu table {
  background-color: black;
  height: 100%;
  width: 100%;
}

.taulu tr:nth-child(even) {
  background-color: rgb(46, 46, 46) !important;
}

.taulu #btnDelete,
.taulu #btnEdit {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.tietoboxi {
  margin: 25px;
  padding: 15px;
  background-color: rgba(0, 100, 0, 0.589);
  color: white;
  box-shadow: 10px 15px #00310093;
  word-break: break-word;
}

@media only screen and (max-width: 1435px) {
  .tietoboxi {
    margin: 0px;
  }
  .tietoboxi h1,
  .tietoboxi h2,
  .tietoboxi h3 {
    font-size: 200% !important;
  }
}

@media only screen and (max-width: 700px) {
  .tietoboxi h1 {
    font-size: 120% !important;
  }
}

.tietoboxi a {
  color: white;
  text-decoration: none;
}

.tietoameista_linkki:hover {
  transition: 0.5s;
  color: #691553 !important;
  cursor: pointer;
}
.galleria {
  padding-left: 15px;
  padding-right: 15px;
}

.matkailu {
  background-color: rgb(146, 37, 117);
  border-radius: 15px;
}


.m-tausta,
.matkailu section {
  border-top: white solid 1px;
}

.galleria img {
  max-height: 100%;
  padding: 5px;
}

.auki p {
  font-size: 1.2em;
}

.auki h3 {
  font-size: 1.4em;
}

.poytavaraus_nav {
  margin-right: 48px;
  border-left: 6px outset #f200b1;
  border-right: 6px outset #f200b1;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

@media only screen and (max-width: 1465px) {
  .poytavaraus_nav {
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

@media only screen and (max-width: 1400px) {
  .poytavaraus_nav {
    display: none !important;
  }
}

.ohjeet_border {
  border-left: 6px outset #f200b1;
  border-right: 6px outset #f200b1;
  border-radius: 6px;
}

@media only screen and (max-width: 395px) {
  .ohjeet_border {
    border: none;
  }
}

.accordion-button {
  background-color: #b32e8f !important;
}
.accordion-button h5 {
  color: white;
  padding: 5px;
  font-size: 1.45em;
}
.collapsed {
  background-color: white !important;
}

.collapsed h5 {
  color: black;
}

[aria-expanded="true"]::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-body {
  padding-bottom: 30px;
  text-align: left;
  font-size: 1.1em;
}
.accordion-body p {
  font-size: 1.2em;
}

.K_bg {
  color: black;
}
.lbtn {
  background-color: #f200b1 !important;
  border-radius: 6px;
  border: 4px outset #f200b1;
}
.lbtn:hover {
  background-color: #d60ba0 !important;
  border: 4px outset #d60ba0;
}

.K_bg2 {
  padding-top: 40px;
  padding-bottom: 96px;
}

.new-line {
  white-space: pre-line;
}

.loadingBlock {
  min-height: 800px;
  
}
